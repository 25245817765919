import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransitionService } from '@shared/services/transition-service.service';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { Viewer } from 'marzipano';
import { sharedHotspots, walkthrough, app } from '@shared/data/data.json';
import { StateService } from '@shared/services/state.service';
import { Viewpoint, Hotspot, Walkthrough, ModeAtts } from '@models';

/**
 * Component for viewpoint page. Viewpoints may have existing and proposed screens, night (dark) screen, and Easter Egg screen.
 * "Proposed" mode may also have layers (overlay, vegetation, etc).
 */
@Component({
  selector: 'app-viewpoint',
  templateUrl: './viewpoint.component.html',
  styleUrls: ['./viewpoint.component.scss'],
})
export class ViewpointComponent implements OnInit {
  @ViewChild('tiles') tiles: ElementRef;
  app: any;
  viewer: Viewer = {};
  type: string;
  viewpoint: Viewpoint;
  sceneHotspots: any = [];
  viewpoints: Viewpoint[];
  sharedHotspots: any;
  walkthrough: Walkthrough;
  state: any;
  selectedLayerSlug: ModeAtts;
  private existingScene: any;
  private proposedScene: any;
  private currentProposedScene: any; // this is used to keep track of layer scenes
  private darkScene: any;
  private easterEggScene: any;
  private homeViewpointSlug: string = 'site';
  private refreshId: any;

  constructor(
    private route: ActivatedRoute,
    private viewpointsService: ViewpointsService,
    private transitionService: TransitionService,
    private stateService: StateService,
    private router: Router
  ) {
    // force route reload whenever params change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.sharedHotspots = sharedHotspots;
    this.walkthrough = walkthrough;
    this.app = app;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      let viewpointSlug = params['name'];
      this.viewpoints = this.viewpointsService.getViewpoints();
      if (!viewpointSlug) {
        viewpointSlug = this.homeViewpointSlug;
      }
      this.viewpoint = this.viewpointsService.getViewpoint(viewpointSlug);
      this.type = this.viewpoint.parent ? 'existing' : 'proposed';
      this.state = this.stateService.getState();
      if (!this.state.viewpoints[this.viewpoint.slug]) {
        this.state.viewpoints[this.viewpoint.slug] = {};
        this.stateService.setState(this.state);
      }
    });
  }

  ngAfterViewInit(): void {
    this.initPanos();
  }

  ngOnDestroy(): void {
    // this.viewer.destroy();
    this.stop();
  }

  /**
   * Create the hotspots needed for a scene.
   * @param scene
   * @param viewpoint
   * @param type
   * @param altId
   */
  createSceneHotspots(scene, viewpoint, type, altId = '') {
    if (viewpoint.mode[type].sharedHotspotsSlug) {
      let hotspots: Hotspot[] = this.sharedHotspots[viewpoint.mode[type].sharedHotspotsSlug];
      for (let i = 0; i < hotspots.length; i++) {
        this.sceneHotspots.push({
          scene: scene,
          sceneId: viewpoint.slug + '-' + altId,
          hotspot: hotspots[i],
        });
      }
    }
  }

  findActiveProposedLayer() {
    this.viewpoint.mode.proposed.layers.forEach((layer: any) => {
      if (layer.isActive) {
        this.selectedLayerSlug = layer
      }
    })
  }

  /**
   * Set up the Marzipano panos and hotspots needed for the current viewpoint.
   */
  initPanos() {
    const panoElement = this.tiles.nativeElement;
    this.viewer = new Viewer(panoElement, {
      stage: { progressive: true },
    });

    // Creates a default scene to swap back to if needed
    if (this.viewpoint.mode.existing) {
      this.proposedScene = this.createScene(
        this.viewpoint,
        this.viewpoint.mode.proposed.layers[0].slug
      )
    }
    else {
      this.proposedScene = this.createScene(
        this.viewpoint,
        this.viewpoint.mode.proposed.slug
      )
    }

    // Functionality to swap between existing and proposed stages
    this.transitionService.currentScene = this.proposedScene;

    // NOTE: All viewpoints default to proposed
    // Only target sub-viewpoints
    if (this.viewpoint.mode.existing) {
      this.viewpoint.mode.proposed.layers[0].isActive = true
      this.transitionService.currentScene = this.createScene(this.viewpoint, this.viewpoint.mode.proposed.layers[0].slug)
    }
    
    // Add in hotspots if there are any
    this.createSceneHotspots(this.proposedScene, this.viewpoint, 'proposed');

    this.currentProposedScene = this.proposedScene;
    this.transitionService.currentScene.switchTo();
    this.start();
  }

  /**
   * Create a scene for the current viewpoint.
   * @param viewpoint
   * @param sceneSlug
   * @returns
   */
  private createScene(viewpoint, sceneSlug) {
    let scene = this.transitionService.createMarzipanoScene(
      viewpoint,
      this.viewer,
      `../../assets/tiles/${sceneSlug}/{z}/{f}/{y}/{x}.jpg`
    );
    return scene;
  }

  /**
   * Toggle the scene "mode" between "existing" and "proposed".
   * @param args
   */
  public toggleScene = (args): void => {
    let mode = 'existing';
    if (args.checked) {
      mode = 'proposed';
    }

    this.state.global.mode = mode;
    this.stateService.setState(this.state);
    this.transitionService
      .nextScene(this.existingScene, this.currentProposedScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Toggle the layer scene. Uses data in the layers.map data structure in "shared/data/data.json".
   * @param args
   */
  public toggleLayerScene = (args: any): void => {
    let layerScene = this.createScene(this.viewpoint, args.slug);
    this.transitionService
      .nextScene(this.currentProposedScene, layerScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
    this.transitionService.currentScene = layerScene;
    this.currentProposedScene = layerScene;
  };

  /**
   * Toggle the Dark scene.
   * @param $event
   */
  public toggleDarkScene = ($event): void => {
    this.transitionService
      .nextScene(this.proposedScene, this.darkScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Toggle the Easter Egg scene.
   * @param $event
   */
  public toggleEasterEggScene = ($event): void => {
    this.transitionService
      .nextScene(this.proposedScene, this.easterEggScene)
      .switchTo({
        transitionDuration: this.transitionService.TRANSITION_DURATION,
      });
  };

  /**
   * Starts the polling using in the compass and map viewcone rotation.
   */
  start() {
    let that = this;
    this.refreshId = setInterval(function () {
      let viewParams = that.viewer.view().parameters();
      that.viewpoint.yaw = viewParams.yaw;
      that.viewpointsService.onUpdateViewpoint.emit(that.viewpoint);
    }, 100);
  }

  /**
   * Clear the polling using in the compass and map viewcone rotation.
   */
  stop() {
    clearInterval(this.refreshId);
  }
}
