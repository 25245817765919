import { Injectable, ElementRef, Output, EventEmitter } from '@angular/core';
import * as Marzipano from 'marzipano';
import { isDevMode } from '@angular/core';

/**
 * Service for managinf transitions of Marzipano scenes.
 */
@Injectable({
  providedIn: 'root',
})
export class TransitionService {
  private DEFAULT_VFOV = [0.698131111111111, 2.09439333333333];
  private DEFAULT_HFOV = [0.698131111111111, 2.09439333333333];
  private DEFAULT_PITCH = [-0.05, 0.18];
  public TRANSITION_DURATION = 200;
  public currentScene;
  private isAddDevTools: boolean = true; // click helper to find current yaw, pitch
  private scene: any;


  private readonly geometry = new Marzipano.CubeGeometry([
    {
      "tileSize": 256,
      "size": 256,
      "fallbackOnly": true
    },
    {
      "tileSize": 512,
      "size": 512
    },
    {
      "tileSize": 512,
      "size": 1024
    },
    {
      "tileSize": 512,
      "size": 2048
    },
    {
      "tileSize": 512,
      "size": 4096
    },
    {
      "tileSize": 512,
      "size": 8192
    }
  ]);

  /**
   * Create a Marzipano Scene object.
   * @param viewpoint 
   * @param viewer 
   * @param tilesPath 
   * @returns Marzipano scene
   */
  public readonly createMarzipanoScene = (
    viewpoint,
    viewer,
    tilesPath: string,
  ) => {
    let vfov = viewpoint.vfov || this.DEFAULT_VFOV;
    let hfov = viewpoint.hfov || this.DEFAULT_HFOV;
    let pitch = viewpoint.pitch || this.DEFAULT_PITCH;
    const limiter = Marzipano.util.compose(
      Marzipano.RectilinearView.limit.vfov(vfov[0], vfov[1]),
      Marzipano.RectilinearView.limit.hfov(hfov[0], hfov[1]),
      Marzipano.RectilinearView.limit.pitch(pitch[0], pitch[1])
    );
    const source = Marzipano.ImageUrlSource.fromString(tilesPath);
    this.addDevTools(viewer);
    const view = this.getRectilinearView(viewer, viewpoint, limiter)
    return viewer.createScene({
      source,
      geometry: this.geometry,
      view: view,
      pinFirstLevel: true,
    });
  };

  private getRectilinearView(viewer, viewpoint, limiter) {
    const view = viewer.view()
    return new Marzipano.RectilinearView(
      {
        pitch: view?.pitch() || -0.05,
        yaw: view?.yaw() || viewpoint.yaw,
        fov: view?.fov() || 1,
      },
      limiter
    );
  }

  /**
   * Displays yaw, pitch and fov values in console when screen is clicked on (in dev mode only).
   * @param viewer 
   * @returns 
   */
  private addDevTools(viewer) {
    if (!isDevMode() && this.isAddDevTools) {
      return;
    }
    let pano = viewer._domElement;
    pano.addEventListener('click', function (e) {
      var view = viewer.view();
      console.log(JSON.stringify({
        yaw: view.yaw(),
        pitch: view.pitch(),
        fov: view.fov()
      }))
    });
  }

  /**
   * Makes the new scene maintain the same yaw, pitch and fov as the previous scene.
   * @param currentScene 
   * @param nextScene 
   */
  private readonly maintainPointOfView = (currentScene, nextScene): void => {
    nextScene.view().setParameters({
      pitch: currentScene.view().pitch(),
      yaw: currentScene.view().yaw(),
      fov: currentScene.view().fov(),
    });
  };

  /**
   * Facilitates the switching betwen two scenes.
   * @param beforeScene 
   * @param afterScene 
   * @returns 
   */
  public readonly nextScene = (beforeScene, afterScene) => {
    switch (this.currentScene) {
      case beforeScene:
        this.maintainPointOfView(beforeScene, afterScene);
        return (this.currentScene = afterScene);
      case afterScene:
        this.maintainPointOfView(afterScene, beforeScene);
        return (this.currentScene = beforeScene);
      default:
        this.maintainPointOfView(afterScene, beforeScene);
        return (this.currentScene = afterScene);
    }
  };
}
