import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModeAtts, Viewpoint } from '@models/viewpoint.model';
import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input('viewpoint') viewpoint: Viewpoint;
  @Input('proposedView') proposedView: boolean = false;
  @Input('selectedLayer') selectedLayer: ModeAtts;
  @Output() checkLayer = new EventEmitter();
  isActive: boolean = false;
  app: any;

  constructor() {
    this.app = app
  }

  ngOnInit(): void {
    this.isActive = false
  }

  activateLayer(layer) {
    // Set all layers to false by default
    if (this.proposedView) {
      this.viewpoint.mode.proposed.layers.forEach((proposedLayer: any) => {
        proposedLayer.isActive = false
      })
    }
    // Enable the target layer
    layer.isActive = true
    this.checkLayer.emit(layer)
  }

  toggleStage() {
    this.proposedView = !this.proposedView

    // Find the proposed layer if selected, otherwise use the existing layer
    if (this.proposedView) {
      this.findActiveProposedLayer()
    }
    else {
      this.selectedLayer = this.viewpoint.mode.existing
    }
    
    this.activateLayer(this.selectedLayer)
  }

  findActiveProposedLayer() {
    this.viewpoint.mode.proposed.layers.forEach((layer: any) => {
      if (layer.isActive) {
        this.selectedLayer = layer
      }
    })
  }
}
