import { Component, Input, isDevMode, NgZone, OnInit } from '@angular/core';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.scss'],
})
export class WalkthroughComponent implements OnInit {
  @Input('data') data: any;
  index: number = 0;
  currentStep: any;
  isShowWalkthrough: boolean = false;
  isShowPopup: boolean = false;
  isRemind: boolean = false;

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    if (isDevMode()) {
      this.addDevTools();
    }
    let dismissWalkthrough = JSON.parse(
      localStorage.getItem(this.data.slug + '_dismissed')
    );
    if (!dismissWalkthrough) {
      this.isShowWalkthrough = true;
      this.displayStep();
      this.addReminder();
    }
  }

  displayStep() {
    this.isShowPopup = false;
    this.currentStep = this.data.steps[this.index];
    let that = this;
    this.ngZone.runOutsideAngular(() => {
    setTimeout(function () {
      that.isShowPopup = true;
    }, 600);
  });
  }

  addReminder() {
    var body: HTMLElement = document.querySelector('body');
    let that = this;
    body.addEventListener('click', function (event) {
      let popup : HTMLElement = document.querySelector('.walkthrough-popup');
      if(popup && that.isShowPopup){
        that.isRemind = true;
        setTimeout(function(){
          that.isRemind = false;
        }, 300);
      }
    });
  }

  next() {
    if (this.data.steps.length > this.index + 1) {
      this.index++;
      this.displayStep();
    }
  }

  done() {
    localStorage.setItem(this.data.slug + '_dismissed', 'true');
    this.isShowWalkthrough = false;
  }

  togglePopup() {
    this.isShowPopup = !this.isShowPopup;
  }

  addDevTools() {
    var body: HTMLElement = document.querySelector('body');
    body.addEventListener('click', function (event) {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      let left = event.clientX;
      let top = event.clientY;
      var right = vw - left;
      var bottom = vh - top;

      let step = {
        text: 'Step text goes here...',
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        popupVertAlign: 'bottom',
        popupHorizAlign: 'right',
      };
      console.info(JSON.stringify(step));
    });
  }
}
